<template>
  <section class="confirm-bind">
    <div v-if="isAlexa" class="confirmBox">
      <!-- logo -->
      <div class="bind-img">
        <img class="oem-logo" :src="logo" />
        <img class="link" src="@/assets/images/alexa/ic_n_link@3x.png" />
        <img class="alexa-logo" src="@/assets/images/alexa/ic_n_alexa@3x.png" />
      </div>
      <!-- 描述 -->
      <div class="decs">
        <h1 class="h1">{{ langBind.title1 }}</h1>
        <p class="p">
          {{ langBind.desc1 }}
        </p>
      </div>
      <!-- 授权描述 -->
      <div class="auth-sec">
        <h1 class="h1">{{ langBind.title2 }}</h1>
        <p class="p mar-t">{{ langBind.desc2 }}</p>
        <p class="p">{{ langBind.desc3 }}</p>
      </div>
    </div>
    <!-- //google -->
    <div class="confirmBox google" v-else>
      <!-- logo -->
      <div class="bind-img googleColunm">
        <img class="oem-logo" :src="logo" />
        <h3>{{ title }}</h3>
      </div>
      <!-- 描述 -->
      <div class="decs">
        <h1 class="h1">{{ langBind.titleGoogle1 }}</h1>
        <p class="p">
          {{ langBind.descGoogle1 }}
        </p>
      </div>
      <!-- 授权描述 -->
      <div class="auth-sec">
        <p class="p mar-t">{{ langBind.descGoogle2 }}</p>
        <!-- <p class="p">{{ langBind.descGoogle3 }}</p> -->
      </div>
    </div>
    <!-- 按钮 -->
    <div class="btns">
      <el-button
        v-dbClick
        @click="bindNow"
        class="bind-btn"
        type="primary"
        round
      >
        {{ langBind.allow }}
      </el-button>
      <el-button @click="cancel" class="cancel-btn" round>{{
        langBind.cancel
      }}</el-button>
    </div>
  </section>
</template>

<script>
import { APP_CONFIG } from "@/utils/appConstant";
import { mapState } from "vuex";
// import { langBind } from "./language";
const oemLogo = require("@/assets/images/oem_logo.png");
const oem_logo_1104 = require("@/assets/images/oem_logo_1104.png");

const logo_arr = {
  1020: oemLogo,
  1104: oem_logo_1104,
};
export default {
  props: ["appId"],
  data() {
    return {
      title: APP_CONFIG[this.appId || 1020].appName,
      logo: logo_arr[this.appId || 1020],
    };
  },
  computed: {
    ...mapState("alexaLogin", ["tabIndex", "languagePackage"]),
    langBind() {
      return this.languagePackage[this.tabIndex];
    },
    isAlexa() {
      return this.$commonFunc.getUrlChannel() == "alexa";
    },
  },
  methods: {
    bindNow() {
      this.$commonFunc.linkRouter(this.$route.query);
      // const { state, authorizationCode, redirect_uri } = this.$route.query;
      // // window.location.href = `${redirect_uri}?state=${state}&code=${authorizationCode}`;
      // let url = "";
      // if (redirect_uri.indexOf("?code") != -1) {
      //   url = `${redirect_uri}=${authorizationCode}&state=${state}`;
      // } else {
      //   url = `${redirect_uri}?code=${authorizationCode}&state=${state}`;
      // }
      // window.location.href = url;
    },
    cancel() {
      this.$router.back(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.confirm-bind {
  font-size: 14px;
  .confirmBox {
    padding-top: 44px;
  }
  .bind-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;

    .oem-logo {
      width: 64px;
      height: 64px;
    }

    .link {
      margin: 0 3px 0 14px;
      width: 40px;
      height: 40px;
    }

    .alexa-logo {
      width: 72px;
      height: 72px;
    }
  }

  .decs {
    .h1 {
      margin-top: 43px;
      text-align: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.9);
      font-weight: 700;
    }

    .p {
      margin-top: 12px;
      padding: 0 32px;
      font-size: 14px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .auth-sec {
    margin: 24px 32px 0;
    padding-top: 16px;
    border-top: 1px solid #e5e5e5;
    .h1 {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
    }
    .p {
      line-height: 26px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);
      &.mar-t {
        margin-top: 5px;
      }
    }
  }

  .google {
    padding-top: 84px;
    .googleColunm {
      flex-direction: column;
      margin-top: 40px;
      h3 {
        margin-top: 14px;
      }
    }
    .decs {
      .h1 {
        margin-top: 50px;
        margin-bottom: 30px;
        font-size: 22px;
        padding: 0 32px;
      }
      .p {
        font-size: 16px;
        color: #222222;
        font-weight: 500;
      }
    }
    .auth-sec {
      border: none;
      margin-top: 0;
      margin-bottom: 90px;
      .p {
        font-size: 14px;
        color: #666666;
        position: relative;
        padding-left: 10px;
        &:before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #999999;
          top: 10px;
          left: 0px;
        }
      }
    }
  }

  .btns {
    padding: 0 32px;

    .bind-btn,
    .cancel-btn {
      width: 100%;
      display: block;
      margin: auto 0;
      font-weight: 500;
    }

    .bind-btn {
      margin-top: 32px;
    }

    .cancel-btn {
      margin-top: 12px;
    }
  }
}
</style>
